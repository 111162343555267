import { taikoTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

// TODO: Add Mantle Chain
export const farmsV3 = defineFarmV3Configs([
  {
    pid: 3,
    token0: taikoTokens.weth,
    token1: taikoTokens.taiko,
    lpAddress: '0xEb5BE83E5cc05C2158b39B37222b9C44DbE8CaC7',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 4,
    token0: taikoTokens.usdc,
    token1: taikoTokens.weth,
    lpAddress: '0xde634b8363488Aa49a4A5F69fc66D3cb35962676',
    feeAmount: FeeAmount.MEDIUM,
  },
  {
    pid: 5,
    token0: taikoTokens.weth,
    token1: taikoTokens.wsxeth,
    lpAddress: '0xc7Cbac1bB6C37570c04609FB70B2959c8b8b4412',
    feeAmount: FeeAmount.LOWEST,
  },
  {
    pid: 6,
    token0: taikoTokens.weth,
    token1: taikoTokens.mbtc,
    lpAddress: '0xE1F84312952a6F98444E65d0B1A8f7b55609b13E',
    feeAmount: FeeAmount.MEDIUM,
  },
  // {
  //   pid: 7,
  //   token0: taikoTokens.unibtc,
  //   token1: taikoTokens.mbtc,
  //   lpAddress: '0x7860371fc664A85B40Fe5FDB93828d57881fd064',
  //   feeAmount: FeeAmount.MEDIUM,
  // },

  // {
  //   pid: 2,
  //   token0: mantleTokens.usdc, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.wmnt, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x48AeaE837b8ebac441Ed8d00B6C5df6D3208C673',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 3,
  //   token0: new ERC20Token(
  //     ChainId.MANTLE,
  //     '0x4206A257Dbc46e4145d31eE6b64717Bd455817a7',
  //     18,
  //     'TEST 1',
  //     'PancakeSwap Token',
  //     'https://mancakeswap.finance/',
  //   ),
  //   token1: mantleTokens.wmnt,
  //   lpAddress: '0xcb9C5e852d7874Ffab7F76DB78AF2cd05aFDd647',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
])

// const farms: SerializedFarmConfig[] = [
//   /**
//    * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
//    */
//   {
//     pid: 2,
//     v1pid: 2,
//     lpSymbol: 'USDT-USDC LP',
//     lpAddress: '0xf805D18e057450182df1b877319497344586Dbc8',
//     token: mantleTokens.usdt,
//     quoteToken: mantleTokens.usdc,
//     stableSwapAddress: '0x1940Bf7e1d84A6f1f9F90A6290189601617736cb',
//     infoStableSwapAddress: '0x17287d20cFd3bC884803816684333c853F2B06ef',
//     stableLpFee: 0.0002,
//     stableLpFeeRateOfTotalFee: 0.5,
//   },
// ].map((p) => ({
//   ...p,
//   token: p.token.serialize,
//   quoteToken: p.quoteToken.serialize,
//   lpAddress: getAddress(p.lpAddress),
// }))

// <<<<<<< HEAD
// // const farms: SerializedFarmConfig[] = [
// //   {
// //     pid: 1,
// //     v1pid: 1,
// //     lpSymbol: 'USDT-USDC LP',
// //     lpAddress: '0x53493036c409D450Ab0dA326799a52DD4024c738',
// //     token: taikoTokens.usdc,
// //     quoteToken: taikoTokens.usdt,
// //     stableSwapAddress: '0x59dcbEd743Be740E0815E4A5fAB236F00e8Ad92E',
// //     infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
// //     stableLpFee: 0.0002,
// //     stableLpFeeRateOfTotalFee: 0.05,
// //   },
// // ]

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 2,
    v1pid: 2,
    lpSymbol: 'USDT-USDC LP',
    lpAddress: '0x2686b6f35bF0A50dEE81a9B0ea8B02BC8C325C9F',
    token: taikoTokens.usdt,
    quoteToken: taikoTokens.usdc,
    stableSwapAddress: '0x3136Ef69a9E55d7769cFED39700799Bb328d9B46',
    infoStableSwapAddress: '0xBa4E38d1F440453d8652d1c0Fe72B35faE6D3150',
    stableLpFee: 0.0005,
    stableLpFeeRateOfTotalFee: 0.05,
  },
  {
    pid: 3,
    v1pid: 3,
    lpSymbol: 'SolvBTC-SolvBTCBBN LP',
    lpAddress: '0x42aA0a6107EedAcD9598e4Ef359B9D60FB358380',
    token: taikoTokens.solvbtc,
    quoteToken: taikoTokens.solvbtcbbn,
    stableSwapAddress: '0xC55E123cf0a6E7e9221174F0A7501E85FebaA723',
    infoStableSwapAddress: '0xBa4E38d1F440453d8652d1c0Fe72B35faE6D3150',
    stableLpFee: 0.0005,
    stableLpFeeRateOfTotalFee: 0.05,
  },
  {
    pid: 4,
    v1pid: 4,
    lpSymbol: 'USDa-sUSDa LP',
    lpAddress: '0x3f9504ba357f45e460a114f95ef7eFda8d813DE3',
    token: taikoTokens.usda,
    quoteToken: taikoTokens.susda,
    stableSwapAddress: '0x8A2219ad9099652882B335d09d5126C66b8627e5',
    infoStableSwapAddress: '0xBa4E38d1F440453d8652d1c0Fe72B35faE6D3150',
    stableLpFee: 0.0005,
    stableLpFeeRateOfTotalFee: 0.05,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

// const farms: SerializedFarmConfig[] = []

export default farms
